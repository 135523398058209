import { browserType } from "../DataTypes/dataTypes";

export default class Browsering {
    private static _userAgent = navigator.userAgent
    static _isSafariMobile = navigator.userAgent.indexOf("Safari") !== -1 && navigator.userAgent.indexOf("Mobile")!==-1
    static _isMac = navigator.userAgent.indexOf("Safari") !== -1

    static get detectBrowser(): "Opera"|"Firefox"|"Safari"|"IE"|"Edge"|"Chrome"|"EdgeChromium"|"Blink"|null {
        try{
            // Opera 8.0+
            // @ts-ignore
            var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
            if(isOpera){
                return "Opera"
            }
            // Firefox 1.0+
            // @ts-ignore
            var isFirefox = typeof InstallTrigger !== 'undefined';
            if(isFirefox){
                return "Firefox"
            }
            // Safari 3.0+ "[object HTMLElementConstructor]" 
            // @ts-ignore
            var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
            if(isSafari){
                return "Safari"
            }
            // Chrome 1 - 79
            // @ts-ignore
            var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
            if(isChrome){
                return "Chrome"
            }
            // Internet Explorer 6-11
            // @ts-ignore
            var isIE = /*@cc_on!@*/false || !!document.documentMode;
            if(isIE){
                return "IE"
            }
            // Edge 20+
            // @ts-ignore
            var isEdge = !isIE && !!window.StyleMedia;
            if(isEdge){
                return "Edge"
            }
            // Edge (based on chromium) detection
            // @ts-ignore
            var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") !== -1);
            if(isEdgeChromium){
                return "EdgeChromium"
            }
            // Blink engine detection
            // @ts-ignore
            var isBlink = (isChrome || isOpera) && !!window.CSS;
            if(isBlink){
                return "Blink"
            }
            return null
        } catch(e){
            console.warn("ERROR WHILE DETECTING BROWSER", e)
            return null
        }
    }

    static get browser(): browserType{
        let userAgent = navigator.userAgent;
        let browserName:browserType;
        
        if(userAgent.match(/chrome|chromium|crios/i)){
            browserName = "Chrome";
          }else if(userAgent.match(/firefox|fxios/i)){
            browserName = "Firefox";
          }  else if(userAgent.match(/safari/i)){
            browserName = "Safari";
          }else if(userAgent.match(/opr\//i)){
            browserName = "Opera";
          } else if(userAgent.match(/edg/i)){
            browserName = "Edge";
          }else{
            browserName = null;
          }

          return browserName
    }


    static get isSafariMobile(){
        return this._isSafariMobile
    }
    
    static get userAgent(){
        return this._userAgent
    }

    static get isMac(){
        return this._isMac
    }
}