
import {baseResponse, submitFormBody} from "../DataTypes/baseResponse"
import { fetchData } from "../Helpers/FetchData"

export type watchChannelBody = {
    userToken: string;
    channelToken: string;
}

export class UserDataProvider {

    // static getSiteName(){
    //     return fetchData(
    //         'user/sitename',
    //         'GET',
    //         {}
    //     )
    // }

    static saveUser(body: submitFormBody){
        return fetchData(
            'client',
            'POST',
            {
                phonenumber: body.phonenumber,
                email: body.email,
                fullname: body.fullname,
                type: body.type,
                lang: body.lang
            }
        )
    }

}

export async function loadData(dataProvider: (body:any)=>any, body: {}) : Promise<baseResponse> {
    try { 
        const response:baseResponse = await dataProvider(body)
        if(response.statusCode<200){ // system error exception
            // console.error("STATUS CODE", response)
        }
        return response
    } catch(e){
        console.log('error', e)
        throw e
    }
}