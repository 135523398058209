// import success from '../assets/images/buttons/success.png'
// import error from '../assets/images/buttons/error.png'
// import edit from '../assets/images/buttons/edit.png'
// import info from '../assets/images/buttons/info.png'
// import warning from '../assets/images/buttons/warning.png'
// import primary from '../assets/images/buttons/info.png'
// import hidden from '../assets/images/buttons/info.png'
import noPictures from '../assets/images/main/no-pictures.png'
// import mainlogo from '../assets/images/main/logotype.png'
// import mainlogo from '../assets/images/main/ChobalWhite.png'
import mainlogo from '../assets/images/main/ChobalBlink.png'
import mainlogoBlack from '../assets/images/main/ChobalBlack.png'

export const ICONS = {
   noPictures,
   mainlogo,
   mainlogoBlack
}