import { coreupdate } from "../Actions/actions";
import { UPDATE } from "../Actions/types";
import { isMobileWidth } from "../Constants/constants";
import { readData } from "../Helpers/readData";
import { saveData } from "../Helpers/saveData";

type screen = {
    w: number;
    h: number;
}

type modes = "white" | "dark";
class MediaController {
    private _screen: screen
    private _mode: modes
    constructor(){
        this._screen = {
            w: window.innerWidth,
            h: window.innerHeight
        }
        this._mode = "white";
        this.getMode();
        this.reinit = this.reinit.bind(this)
    }

    get mode(){
        return this._mode
    }

    getMode = async () => {
        const data = await readData("mode");
        if(data === "white" || data === "dark"){
            this.changeMode(data)
        }
    }
    saveMode = async () => {
        saveData("mode", this._mode)
    }

    toggleMode = () => {
        this.changeMode(this._mode === "white" ? "dark" : "white")
    }

    changeMode = (mode: modes) => {
        this._mode = mode;
        if(this._mode === "dark"){
            this.darkMode()
        } else {
            this.whiteMode();
        }
        coreupdate(UPDATE.LOGO)
        this.saveMode()
    }

    whiteMode = () => {
        const doc = document.getElementById('mainscroll')
        doc?.classList.remove('dark-mode')
    }

    darkMode = () => {
        const doc = document.getElementById('mainscroll')
        doc?.classList.add('dark-mode')
    }

    reinit(){
        this._screen = {
            w: window.innerWidth,
            h: window.innerHeight
        }
        this.reinitFunctions()
        // console.log('reinit', this._screen)
    }

    reinitFunctions(){
        this.loadDependsPage()
    }

    loadDependsPage(){
        // if(window.location.pathname.indexOf('films') !== -1){
        //     const { filmCategories, filmViewType } = controllers().page.films
        //     if(filmViewType.currentType === "category"){
        //         filmCategories.loads()
        //     }
        // } else if(window.location.pathname.indexOf('series') !== -1){
        //     const { filmCategories, filmViewType } = controllers().page.series
        //     if(filmViewType.currentType === "category"){
        //         filmCategories.loads()
        //     }
        // }
    }

    mainContentClicked(){
        // controllers().menu.userDrawer.hide()
    }

    get w(){
        return this._screen.w
    }

    get h(){
        return this._screen.h
    }

    get screen(){
        return this._screen
    }

    get isMobile(){
        return this._screen.w<isMobileWidth
    }
}

export { MediaController}