import { HomePageModel } from "./PageControllers/HomePageModel";
import { JoinedPage } from "./PageControllers/JoinedPage";
import { PiecesOfHistoryPage } from "./PageControllers/PiecesOfHistoryPage";
import { TargetOrientedPage } from "./PageControllers/TargetOrientedPage";

class PageController {
    
    private readonly _home: HomePageModel;
    private readonly _targetOriented: TargetOrientedPage;
    private readonly _piecesOfHistory: PiecesOfHistoryPage;
    private readonly _joined: JoinedPage;
    
    constructor(){
        this._home = new HomePageModel('home');
        this._targetOriented = new TargetOrientedPage('target-oriented');
        this._piecesOfHistory = new PiecesOfHistoryPage('pieces-of-history');
        this._joined = new JoinedPage('joined');
    }

    get home(){
        return this._home;
    }

    get targetOriented(){
        return this._targetOriented
    }

    get piecesOfHistory(){
        return this._piecesOfHistory
    }

    get joined(){
        return this._joined
    }
}

export { PageController }