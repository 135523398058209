import React from 'react'
import { LoaderController } from '../../Controllers/LoaderController'
import { ViewItem } from '../../Views/Components/Classes/ViewItem'
import { Loading } from '../../Views/Components/Loading'

class MainLoader extends ViewItem {
    get controller():LoaderController{
        return this.props.controller
    }
    render(){
        const {visible} = this.controller
        return <div className={`helper-wrapper loader-wrapper-main ${visible ? "" : 'hidden'}`}>
                <div className="helper-wrapper-box">
                    <div className={`loader `}>
                        <div className="loader-box">
                            <Loading color="white"/>
                        </div>
                </div>
            </div>
        </div>
    }
}

export { MainLoader }


