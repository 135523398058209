import React from 'react' 
import { controllers } from '../../../Controllers/Controller'
import { LangItem } from '../Helpers/LangItem'

type footerItemProps = {
    title: string;
    onClick?: ()=>void;
}

class FooterItem extends React.Component {
    props: footerItemProps
    constructor(props: footerItemProps){
        super(props)
        this.props = props
    }

    clickWithScroll = () => {
        if(this.props.onClick){
            controllers().scroll.scroll(0)
            this.props.onClick()
        }
    }

    render(){
        const {title} = this.props
        return <div className="footer-item">
            <div className="footer-item-box" onClick={this.clickWithScroll}>
                <LangItem for={title}/>
            </div>
        </div>
    }
}

export { FooterItem }
