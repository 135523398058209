import { Model } from "../../Core/Components/Model";
import { TargetForm } from "../../Core/Components/TargetForm";
import { loadData, UserDataProvider } from "../../Core/UserDataProvider";
import { controllers } from "../Controller";
import { navigator } from "../Navigator";


class MenuContact extends Model {
    private _type: any;
    private _submitButton: any
    private _loadingNow:boolean
    constructor(){
        super()
        this._type = {label: "Complate", value: "Complate"}
        this._submitButton = null
        this._loadingNow = false
    }

    setSubmitButton = (ref: any) => {
        this._submitButton = ref
    }
    startLoading = () => {
        this._loadingNow = true
        if(this._submitButton){
            this._submitButton.load(true)
        }
    }
    endLoading = () => {
        this._loadingNow = false
        if(this._submitButton){
            this._submitButton.load(false)
        }
    }
    setTypeValue = (e: any) => {
        console.error(e)
        this._type = e
        this.updateMe()
    }

    get type(){
        return this._type
    }

    errorForm = () => {
        alert('Something went wrong');
    }

    onSubmitForm = async (e:any) => {
        e.preventDefault()
        if(this._loadingNow){
            return
        }
        const form = new TargetForm(e)
        
        const body = {
            ...form.body,
            // type: this._type
        }
        console.error("Form", body)
        if(body.fullname === "" || body.fullname === undefined || body.phonenumber === "" || body.phonenumber === undefined || body.email === "" || body.email === undefined){
            return this.errorForm()
        }
        body['language'] = controllers().language.language

        this.startLoading()
        const response = await loadData(UserDataProvider.saveUser, {...body, lang: controllers().language.selectedLanguage})
        this.endLoading()
        if(response.statusCode!==200){
            return this.errorForm()
        }
        navigator().navigate('/joined')
        controllers().menu.rightHide()
    }
}

export { MenuContact }