import React from 'react'
import { controllers } from '../Controllers/Controller'
import { Alert } from '../Models/Helpers/Alert'
import { MainLoader } from '../Models/Helpers/MainLoader'
import { StyleModeView } from './Components/StyleModeView'

class Helpers extends React.Component {
    render(){
        const { loader } = controllers()
        // main navigation
        return <div className="helpers">
            <div className="helpers-box">
                <Alert/>
                <MainLoader ref={loader.set} controller={loader}/>
                <StyleModeView/>
            </div>
        </div>
    }
}

export { Helpers }