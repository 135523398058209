import { coreupdate } from "../Actions/actions"
import { UPDATE } from "../Actions/types"
import { alertTypes, ALERT_TYPES } from "../DataTypes/AlertTypes"

class AlertController {
    private _visible: boolean
    private _title: string
    private _message: string
    private _type: alertTypes|string

    private _showDelay = 4000
    private _timeout:any

    constructor(){
        this._visible = false
        this._title = ""
        this._message = ""
        this._type = ALERT_TYPES.HIDDEN
        this.setShowDelay = this.setShowDelay.bind(this)
        this.hideMe = this.hideMe.bind(this)
        this.showMe = this.showMe.bind(this)
    }

    get visible(){
        return this._visible
    }
    get title(){
        return this._title
    }
    get message(){
        return this._message
    }
    get type(){
        return this._type
    }
    get all(){
        return {
            visible: this._visible,
            title: this._title,
            message: this._message,
            type: this._type,
        }
    }

    setAlert(type: alertTypes|string, message: string, title:string = ""){
        this._type = type
        this._message = message
        this._title = title
    }

    private showMe(){
        this._visible = true
        this.setShowDelay()
        this.updateMe()
    }
    private hideMe(){
        this._visible = false
        this.updateMe()
    }
    private updateMe(){
        coreupdate(UPDATE.ALERT)
    }
    
    show(type: alertTypes|string, message: string, title:string = ""){
        this.setAlert(type,message,title)
        this.showMe()
    }

    hide(){
        // this._type = ALERT_TYPES.HIDDEN
        this.hideMe()
    }

    primary(message: string, title: string = ""){
        this.setAlert(ALERT_TYPES.PRIMARY, message,title)
        this.showMe()
    }

    success(message: string, title: string = ""){
        this.setAlert(ALERT_TYPES.SUCCESS, message,title)
        this.showMe()
    }

    error(message: string, title: string = ""){
        this.setAlert(ALERT_TYPES.ERROR, message,title)
        this.showMe()
    }

    warning(message: string, title: string = ""){
        this.setAlert(ALERT_TYPES.WARNING, message,title)
        this.showMe()
    }
    

    setShowDelay(){
        clearTimeout(this._timeout);
        this._timeout = setTimeout(this.hideMe, this._showDelay)
    }
}

export { AlertController }