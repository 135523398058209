import { UPDATE } from "../../../Actions/types";
import { ICONS } from "../../../Constants/icons";
import { controllers } from "../../../Controllers/Controller";
import { UpdateComponent } from "../../../Core/Components/UpdateComponent";
import { ImageView } from "./ImageView";


class BlackLogoView extends UpdateComponent {

    constructor(props:any){
        super(props)
        this.type = UPDATE.LOGO
    }

    render() {
        const { mode } = controllers().media
        return <ImageView className='main-logo' source={mode === "white" ? ICONS.mainlogoBlack : ICONS.mainlogo}/>
    }
}

export { BlackLogoView }