import { HandleTask } from "../Helpers/minhelpers/HandleTask";
import { ScrollRolling } from "./ControllerHelpers/ScrollRolling";
import { Sequences } from "./ControllerHelpers/Sequences";

class ScrollController {
    private _scrollRef: any;
    private _sequences: Sequences|null;
    private _scrollRoling: ScrollRolling|null
    private _customListener: (e:any)=>void|null
    private _scrollableController: boolean;
    private _scrollable: boolean; 
    private _handler: HandleTask;

    constructor(withSections: boolean = false, scrollableController: boolean = true){
        this._scrollRef = null
        this._sequences = withSections ? new Sequences({scrollController: this}) : null
        this._scrollRoling = withSections ? new ScrollRolling(this) : null
        this._scrollableController = scrollableController
        this._scrollable = scrollableController
        this._customListener = ()=>null
        this._handler = new HandleTask()
    }

    get handler(){
        return this._handler
    }

    get scrollableController(){
        return this._scrollableController
    }

    makeScrollable = (bool: boolean) => {
        if(this._scrollable === bool){
            return
        }
        this._scrollable = bool
        if(this._scrollRef){
            this._scrollRef.style.overflow = bool ? 'auto' : 'hidden'
        }
    }

    get sequences(){
        return this._sequences
    }

    get scrollRef(){
        return this._scrollRef
    }

    set = (ref: any) => {
        this._scrollRef = ref
        this._handler.do()
        if(this._sequences == null){
            return
        }
        // this.sequences?.makeSections()
    }

    makeSections = (withSelection: boolean = false) => {
        // console.error("SECTIONS", this._sequences, this._scrollRef, withSelection)
        if(this._sequences == null){
            return
        }
        // console.warn('makeSections with selection', withSelection);
        if(this._scrollRef === null){
            const appendFunc = ()=> {
                this._sequences?.makeSections(withSelection)
            }
            this._handler.append(appendFunc)
            return
        }
        this._sequences.makeSections(withSelection)
    }

    get scrollRolling(){
        return this._scrollRoling
    }

    scrollToSection = (leftOffset: number) => {
        if(this._sequences == null){
            return
        }
        this._sequences.scrollToSectionByX(leftOffset)
    }

    onScrollEvent = (e: any) => {
        // console.log("EVENT", e)
        if(this._scrollRoling == null){
            return
        }
        this._scrollRoling.onScrollEvent(e)
    }

    addListenersToScroll = () => {
        // if(this._scrollRoling == null){
        //     return
        // }
        if(this._scrollRef === null){
            this._handler.append(this.addListenersToScroll)
            return
        }
        this._scrollRef.addEventListener('scroll', this.onScrollEvent)
    }

    removeListenersToScroll = () => {
        // if(this._scrollRoling == null){
        //     return
        // }
        this._scrollRef.removeEventListener('scroll', this.onScrollEvent)
    }

    customListenersToScroll = (custom: (e: any)=>void) => {
        this._customListener = custom
    }

    makeCustomListeners = () => {
        if(this._customListener(null)==null){
            // console.error('this._customListener(null)==null')
            return
        }
        if(this._scrollRef == null){
            this._handler.append(this.makeCustomListeners)
            return
        }
        this._scrollRef.addEventListener('scroll', this._customListener)
        // this._scrollRef.onscroll = this._customListener
    }
    removeCustomListeners = () => {
        if(this._customListener(null)==null){
            return
        }
        if(this._scrollRef == null){
            return
        }
        this._scrollRef.removeEventListener('scroll', this._customListener)
    }

    scroll = (y: number, x: number = 0) => {
        try{
            if(this._scrollRef === null){
                // alert("NO SCROLL REF")
                return console.log(`can't scroll now`);
            }
            if(typeof this._scrollRef.scroll === "function"){
                // alert("SCROLL IS FUNCTION")
                this._scrollRef.scroll(x, y)
            } else if(typeof this._scrollRef.scrollTo === "function"){
                // alert("SCROLLTO")
                this._scrollRef.scrollTo(x, y)
            } else {
                // alert("SCROLLTOP")
                // controllers().alert.error(JSON.stringify(this._scrollRef));
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            }
        } catch(e){
            console.log('scroll ex', e)
        }
    }
}

export { ScrollController }