import store from "../redux"
import { UPDATE } from "./types"

// update some in redux LOADER || AUTH || ALERT
const coreupdate = (type:any) => {
    store.dispatch({type})
}

const tableupdate = (tableType: any) => {
    store.dispatch({type: UPDATE.TABLE, data: tableType})
}

const modalupdate = (modalType: any) => {
    store.dispatch({type: UPDATE.MODAL, data: modalType})
 }

export {
    coreupdate,
    tableupdate,
    modalupdate
}