import { coreupdate } from "../Actions/actions"
import { UPDATE } from "../Actions/types"
import { Model } from "../Core/Components/Model"
import { langs } from "../DataTypes/languageTypes"
// import { controllers } from "./Controller"
import { English } from "./Languages/English"
import { Deutsch } from "./Languages/Deutch"
import { Lang } from "./Languages/Lang"
import { saveData } from "../Helpers/saveData"
import { readData } from "../Helpers/readData"
import { controllers } from "./Controller"
import { seo } from "./SeoController"


class LanguageController extends Model {

    private _languages: Array<Lang>
    private _language: langs
    private _isMobile: boolean
    private _arrWords:Array<string> = [];
    private _defaultLanguage: langs = "en"
    private _restored: boolean
    constructor(){
        super();
        this._languages = [
            new English(),
            new Deutsch()
        ]
        this._restored = false;
        this._isMobile = false
        this._language = this._defaultLanguage
    }

    // addItem = (string: string) => {
    //     const find = this._arrWords.find(it => it === string)
    //     if(find){

    //     } else {
    //         this._arrWords.push(string)
    //     }
    // }

    get arrWords(){
        return this._arrWords
    }

    get isMobile() {
        return this._isMobile
    }

    setIsMobile = (bool: boolean) => {
        if(this._isMobile === bool){
            return
        }
        this._isMobile = bool
        this.updateMe()
    }

    updateProvider = () => {
        coreupdate(UPDATE.LANG)
    }

    get language(){
        return this._language
    }

    setLanguage = (lang: langs|null, first: boolean = false) => {
        if(lang === null){
            return
        }
        if(this._language === lang && !first){
            return
        }
        this._language = lang
        this.setSelected(lang)
        this.saveLanguage()
        if(!first){
            this.updateLanguage(lang)
            this.updateProvider()
        }
    }

    saveLanguage = () => {
        console.error("SAVED", this._language)
        saveData("language", this._language)
    }

    readLanguage = async (withSet: boolean = false) => {
        const lang = await readData('language')||"en"
        console.error("LANG", lang)
        if(lang && (lang === "en" || lang === "de")){
            this._language = lang
            this.setSelected(lang)
            if(withSet){
                this.updateProvider()
                seo().makeDefaultTitle()
            }
        }
    }

    restoreLang = () => {
        if(this._restored){
            return
        }
        this._restored = true
        const { pathname } = window.location
        if(pathname && pathname.indexOf('de')!==-1){
            controllers().language.setLanguage('de')
        } else {
            controllers().language.readLanguage(true)
        }
    }

    setSelected = ( name : langs) => {
        this._languages.forEach(l => {
            l.setSelected(l.name === name)
        })
        this.updateMe()
    }

    toggleSelected = (name: langs) => {
        this._languages.forEach(l=> {
            l.setSelected(l.name !== name)
        })
        const newLang = this._languages.find(l=>l.selected)?.name||"en"
        this.updateLanguage(newLang)
        this.updateMe()
        this.updateProvider()
    }

    updateLanguage = async (lang: langs) => { 
        // this._language = lang

        // const userToken = controllers().auth.userToken
        // if(userToken === null){
        //     return
        // }

        // const body:changeLanguageBody = {
        //     language_method: lang, 
        //     userToken: userToken
        // }
        // // console.log('body', body)
        // loadData(UserDataProvider.changeLanguage,body)
        // console.log("RESPONSE", response)
    }

    get selectedLanguage(){
        return this._language
    }

    get lang(){
        return this._languages.find(l=>l.name === this._language)
    }

    get langs(){
        return this._languages
    }
}

export { LanguageController }