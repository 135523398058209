import React from 'react'
import '../../assets/styles/menu.css'
import { ViewItem } from '../Components/Classes/ViewItem'
import { MenuController } from '../../Controllers/MenuController'
import { LButton } from '../Components/LButton'
import { ImageView } from './Helpers/ImageView'
import { ICONS } from '../../Constants/icons'
import { BlackLogoView } from './Helpers/BlackLogoView'
class MenuView extends ViewItem {

    get controller(): MenuController{
        return this.props.controller
    }

    render(){
        const { isBlured, leftToggle, rightToggle} = this.controller
        return <div className={`menu ${isBlured ? 'menu-blured' : ''}`}>
            <div className="menu-box">
                <div className='menu-item-button left-item-button'>
                    <LButton icon='bars' type="suit" customStyle='menu-button icon-menu-button' onClick={leftToggle}/>
                </div>
                <div className='main-logo'>
                    {isBlured ? <BlackLogoView/> : <ImageView className='main-logo' source={ICONS.mainlogo}/>}
                </div>
                <div className='menu-item-button right-item-button'>
                    <LButton title='JOIN' type="suit" customStyle='menu-button' onClick={rightToggle}/>
                </div>
            </div>
        </div>
    }
}

export { MenuView }