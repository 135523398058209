import { AppSettings } from "../Constants/AppSettings"
import { capitalize, decodeSimplyName } from "../Helpers/Helpi"
import { RW } from "../Helpers/LangHelper";
// import { RW } from "../Helpers/LangHelper";
import { HandleTask } from "../Helpers/minhelpers/HandleTask"

class SeoController {

    private sitename:string|null = "Chobal restoration"
    private _handleTask: HandleTask;
    constructor(){
        this._handleTask = new HandleTask();
    }

    makeDefaultTitle = () => {
        // console.log("MY TITLE WAS SET");

        const pathname = window.location.pathname
        if(!pathname){
            // console.error("NO PATHNAME")
            return
        }
        if(pathname.indexOf("processing")!==-1){
            console.log("pathname", pathname)
            this.title(RW('Classic car restoration details'))
            this.description(RW('Our team is focused on the restoration of youngtimer and oldtimer cars, which were legends of their time. Only high-quality details and materials we use for restoration. You can be sure that your car will make perfect condition and past thousans kilometers without any problems'))
        } else 
        if(pathname.indexOf("piecesofhistory")!==-1){
            // const channelName = pathname.replace('/watch/movie/', '')
            // this.title(`${decodeSimplyName(channelName)} watch on`)
            // this.description(`${decodeSimplyName(channelName)} movie on`)
            this.title(RW('Car restoration from 60th to 90th'))
            this.description(RW('We direct our ambitions to realize the dreams of many connoisseurs of retro cars. Our works are expertly evaluated by machine critics of German quality at the highest level and have successful evaluations'))
        } else
        if(pathname.indexOf("serie")!==-1){
            const channelName = pathname.replace('/watch/serie/', '')
            this.title(`${decodeSimplyName(channelName)} watch on`)
            this.description(`${decodeSimplyName(channelName)} series on`)
        } else {
            this.title(" ")
        }
        // this.title("BOBER TV");
    }

    title = (title: string) => {
        if(this.sitename === null){
            this._handleTask.appendTask(()=>this.title(title))
            return
        }
        // console.error("TITLE is")
        try {
            if(typeof title === "string"){
                document.title = `${capitalize(title)} ${AppSettings.sitename}`
            } else {
                document.title = `${this.sitename}`
            }
        } catch(e){
            console.error(e)
        }
    }

    description = (description: string ) => {
        if(this.sitename === null){
            this._handleTask.appendTask(()=>this.description(description))
            return
        }
        try {
            var descriptionNew = `${this.sitename}`
            if(typeof description === "string"){
                descriptionNew = `${capitalize(description)} ${AppSettings.sitename}`
            }
            var meta=document.getElementsByTagName("meta");
            for (var i=0; i<meta.length; i++) {
                if (meta[i].name.toLowerCase()==="description") {
                    meta[i].content=descriptionNew
                }
            }
        } catch(e){
            console.error(e)
        }
    }

    url = (urlpath: string) => {
        try{
            if(window.history){
                window.history.replaceState(null, AppSettings.sitename, urlpath)
            } else {
                console.error("NO window.history")
            }
        } catch(e){

        }
    }
}


// @ts-ignore
global.__app__ = global.__app__ || {};
// @ts-ignore
global.__app__.seo =
  // @ts-ignore
  global.__app__.seo || new SeoController();

export function seo(): SeoController {
  // @ts-ignore
  return global.__app__.seo;
}