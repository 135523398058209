import React from "react"
import Select from 'react-select'
import { UPDATE } from "../../Actions/types"
import { RW } from "../../Helpers/LangHelper"
import { UpdateComponent } from "../../Models/Components/UpdateComponent"


const makeLangValue = (value:lOption) => {
    let langValue = {
        ...value
    }
    if(typeof langValue.label === "string"){
        langValue.label = RW(langValue.label)||langValue.label
    }

    return langValue
}
export type lOption = {
    label: string;
    value: string|number;
}

type lSelect = {
    customStyle?: string;
    name?: string; 
    defaultValue?: string; 
    value?: any;
    placeholder?: string;
    setValue?: any;
    title?: string;
    selectOptions?: Array<lOption>;
    controller?: any;
    isSearchable?:boolean
    isMulti?: boolean;
    onChange?: (option: any, any: any)=>void
    stylesFor?: any
}

type stateLSelect = {
    value: lOption | null
}

const customStyles = {
    menu: (provided:any, state:any) => ({
        ...provided,
        color: state.selectProps.menuColor,
        backgroundColor: 'white',
        fontSize: '13px',
    }),
    control: (provided:any, state:any) => ({
        // width: '125px',
        backgroundColor: 'white',
        borderRadius: '5px',
        height: '40px',
        display: 'flex',
    }),
    dropdownIndicator: () => ({
        color: 'black',
        width: '30px',
        display: 'flex',
        justifyContent: 'center'
    }),
    singleValue: () => ({
        color: '#1a1a1c',
        fontFamily: 'Open Sans',
        fontSize: '13px',
        padding: "0 0.3rem",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }),
    option: (provided:any, state:any) => ({
        ...provided,
        color: state.isSelected ? 'hsl(0,0%,80%)': '#BCBABA',
        background: state.isSelected ? 'hsl(0,0%,90%)' : 'none',
        // background: state.selectProps.menuColor,
        boxShadow: state.isSelected ? 'inset 0px 0px 6px rgb(255 255 255 / 25%), inset 0px 0px 39px #000000' : 'none',
    }),
    noOptionsMessage: ()=>({
        fontSize: '13px',
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center',
        margin: '.75rem',
        color: 'black',
    }),
    input: ()=>({
        color: 'black',
        fontFamily: 'Inter',
        maxWidth: '50px',
    }),
    placeholder: ()=>({
        color: 'hsl(0,0%,40%)',
        fontFamily: 'Inter-Bold',
        fontSize: '13px',
    })
  }

class LSelect extends UpdateComponent {
    props: lSelect;
    state: stateLSelect;
    constructor(props: lSelect){
        super(props)
        this.props = props
        this.state = {
            value: null
        }
        this.type = UPDATE.LANG
    }
    render(){
        const {...p} = this.props
        const controller = p.controller
        const selectOptionArr = controller?.selectOptions || p?.selectOptions
        const langSelectOption = selectOptionArr.map((so:lOption)=>{
            return {
                label: RW(so.label)||so.label,
                value: so.value
            }
        })
        return <div className={`input ${p?.customStyle}`}>
            <div className="input-box input-box-select">
                {p.title && <div className="input-title">
                    <div className="input-title-box">
                        {RW(p.title) || p.title}
                    </div>
                </div>}
                {/* @ts-ignore */}
                <Select
                    options={langSelectOption}
                    styles={{
                        ...customStyles,
                        ...p?.stylesFor,
                    }}
                    {...p}
                    value={makeLangValue(p.value)}
                    isSearchable={p?.isSearchable||false}
                    placeholder={RW(p?.placeholder)||p?.placeholder}
                />
            </div>
        </div>
    }
}

export {LSelect}