import { UPDATE } from "../Actions/types";

const initialState = {
    loader: 0,
    auth: 0,
    alert: 0, 
    menu: 0,
    userinfo: 0,
    loading: 0,
    carousel: 0,
    info: 0,
    dictionary: 0,
    lang: 0,
    logo: 0,
    table: {
        some: 0,
    },
    modals: {
        server_info: 0,
    }
};

function update(state){
    return state+1
}

function updateReducer(state = initialState, action) {
    switch(action.type){
        case UPDATE.MODAL: 
            const modals = {
                ...state.modals,
            }
            modals[action.data] = update(state.modals[action.data])
            return {
                ...state,
                modals: modals
            }
        case UPDATE.TABLE: 
            const table = {
                ...state.table,
            }
            table[action.data] = update(state.table[action.data])
            return {
                ...state,
                table: table
            }
        case UPDATE.LOGO:
            return {
                ...state,
                logo: update(state.logo)
            }
        case UPDATE.LANG:
            return {
                ...state,
                lang: update(state.lang)
            }
        case UPDATE.CAROUSEL:
            return {
                ...state,
                carousel: update(state.carousel)
            }
        case UPDATE.DICTIONARY:
            return {
                ...state,
                dictionary: update(state.dictionary)
            }
        case UPDATE.USERINFO:
            return {
                ...state,
                userinfo: update(state.userinfo)
            }
        case UPDATE.LOADER:
            return {
                ...state,
                loader: update(state.loader)
            }
        case UPDATE.LOADING:
            return {
                ...state,
                loading: update(state.loader)
            }
        case UPDATE.AUTH:
            return {
                ...state,
                auth: update(state.auth)
            }
        case UPDATE.ALERT:
            return {
                ...state,
                alert: update(state.alert)
            }
        case UPDATE.MENU:
            return {
                ...state,
                menu: update(state.menu)
            }

        default: return state;
    }
};

export default updateReducer;