import React from "react"
import { LangItem } from "../core/Helpers/LangItem";
import { Fa } from "./Fa"


type contactItemProps = {
    title: string;
    icon: string;
    href: string;
    value: string;
}

class ContactItemView extends React.Component {
    props: contactItemProps
    constructor(props: contactItemProps){
        super(props)
        this.props = props
    }

    render(){
        const { title, icon, href, value} = this.props
        return <div className='contact-info-section'>
                <div className='contact-info-section-title'>
                    <LangItem for={title}/>
                </div>
                <div className='contact-info-value'>
                    <div className='contact-info-value-img'>
                        <Fa icon={icon}/>
                    </div>
                    <a href={href} target="_blank" rel="noreferrer" className='contact-info-value-content'>
                        {value}
                    </a>
                </div>
            </div>
    }
}

export { ContactItemView }