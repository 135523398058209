import { controllers } from "../Controllers/Controller"
import { capitalize } from "./Helpi"

// READ WORD
export const RW = (key: string|undefined) => {
    if(typeof key !== "string"){
        return key
    }
    // controllers().language.addItem(key)
    return controllers().language.lang?.r(key)
}

// READ WORD CAPITALIZE 
export const RWC = (key: string|undefined) => {
    if(typeof key !== "string"){
        return key
    }
    // controllers().language.addItem(key)
    return capitalize(controllers().language.lang?.r(key))
}