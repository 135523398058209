import { UPDATE } from "../../Actions/types";
import { controllers } from "../../Controllers/Controller";
import { UpdateComponent } from "../../Models/Components/UpdateComponent";
import { Fa } from "../../Views/Components/Fa";


class StyleModeView extends UpdateComponent {

    constructor(props: any){
        super(props)
        this.type = UPDATE.LOGO
    }

    render() { 
        const { toggleMode } = controllers().media
        return <div className="mode-chooser">
            <div className="mode-chooser-box" onClick={toggleMode}>
                <div className="mode-circle"/>
                <div className="mode-icon">
                    <Fa icon="sun"/>
                </div>
                <div className="mode-icon white-moon">
                    <Fa icon="moon"/>
                </div>
            </div>
        </div>
    }
}

export {StyleModeView}