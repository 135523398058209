import React from "react";
import { controllers } from "../../Controllers/Controller";
import { LangItem } from "./Helpers/LangItem";
import { ViewItem } from "../Components/Classes/ViewItem";
import { FooterController } from "../../Controllers/PageControllers/FooterController";
// import { ICONS } from "../../Constants/icons";
// import { ImageView } from "./Helpers/ImageView";
import { FooterItemSuit } from "./Footer/FooterItemSuit";
import { FooterItem } from "./Footer/FooterItem";
import { navigator } from "../../Controllers/Navigator";
import { BlackLogoView } from "./Helpers/BlackLogoView";



class FooterView extends ViewItem {

    get controller():FooterController{
        return this.props.controller
    }

    render(){
        const { socials } = this.controller
        return <div className="footer" onClick={controllers().media.mainContentClicked}>
        <div className="footer-box">
            <div className="footer-top">
               Chobal ⓒ 2022
            </div>
            <div className="footer-body">
                <div className="footer-body-center-box">
                    <FooterItemSuit title="Navigation">
                        <FooterItem title="Home" onClick={()=>navigator().navigate('/')}/>
                        <FooterItem title="Target oriented" onClick={()=>navigator().navigate('/processing')}/>
                        <FooterItem title="Pieces of history" onClick={()=>navigator().navigate('/piecesofhistory')}/>
                    </FooterItemSuit>
                </div>
            </div>
            <div className="footer-bottom">
                {socials && socials.length ? <div className="footer-bottom-title">
                    <LangItem for="Contacts"/>:
                </div>: null}
                <div className="footer-bottom-items">
                    
                </div>

                <div className="media-footer-logo">
                    <div className="media-footer-logo-box">
                        <div className="footer-logo">
                            <div className="footer-logo-box">
                                {/* <ImageView source={ICONS.mainlogoBlack} withoutLoader/> */}
                                <BlackLogoView/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    }
}

export { FooterView }