import { controllers } from "./Controller"
import ReactGA from 'react-ga';
const TRACKING_ID = "G-X737324FQ5";

ReactGA.initialize(TRACKING_ID);

class NavigatorImpl {
    // set main navigation function
    private _nav: any
    private _beforeAuth: string = "/";
    private _setMainNav: any;
    private _listenerSets: boolean = false
    private _currentPage: string = window.location.pathname
    constructor() {
        this._nav = null
        this.onLocationChanged(false)
        console.log(this._currentPage)
    }

    get currentPage(){
        return this._currentPage
    }

    setNowRef = (ref: any) => {
        this._setMainNav = ref
    }
    
    updateMe = () => { 
        this._setMainNav.forceUpdate()
    }

    private get nav(){
        return this._nav
    }
    private set nav(value){
        this._nav = value
    }

    initNavigation = (navigation: any) => {
        this._nav = navigation
        if(!this._listenerSets){
            this._nav.history.listen(this.onLocationChanged)
            this._listenerSets = true
        }
    }
    onLocationChanged = (updateNow = true) => {
        const { pathname } = window.location
        const cutted = pathname !== "/" ? pathname.slice(1) : ""
        this._currentPage = cutted.indexOf('/') !== -1 ? "/"+cutted.split('/')[0] : pathname
        if(updateNow){
            this.updateMe()
        }
    }

    private validateNav(){
        return this._nav && this._nav?.history
    }

    private locate(path:string){
        if(!this.validateNav()){
            return
        }
        this._nav.history.push(path)

    }

    navigate(path: string){
        this.locate(path)
        this.afterLocateFunctions()
        // this.menuViewingControl()
    }

    redirect(link: string, target:"_blank"|"" = ""){
        window.location.href = link
    }

    afterLocateFunctions(){
        // controllers().menu.userDrawer.hide()
        controllers().scroll.scroll(0)
        ReactGA.pageview(window.location.pathname)
    }
    // if screen width < 600 hide menu
    menuViewingControl(){
        // if(controllers().media.w<600){
        //     controllers().menu.hide()
        // }
    }

    savePathBeforeNavigate = () => {
        this._beforeAuth = window.location.pathname;
        // console.error("PATH SAVED", this._beforeAuth);
    }

    removeRedirectPath() {
        this._beforeAuth = '/'
    }

    navigateAuthPath = (defaultPath: string) => {
        if(this._beforeAuth !== '/'){
            // console.error('BEFORE AUTH EXIST', this._beforeAuth)
            this.navigate(this._beforeAuth);
            this._beforeAuth = ''
            return
        }
        // console.error('NOT BEFORE EXIST', this._beforeAuth)
        this.navigate(defaultPath)
    }
}

// @ts-ignore
global.__app__ = global.__app__ || {};
// @ts-ignore
global.__app__.navigator =
  // @ts-ignore
  global.__app__.navigator || new NavigatorImpl();

export function navigator(): NavigatorImpl {
  // @ts-ignore
  return global.__app__.navigator;
}
