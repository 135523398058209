import React from "react"
import { colorTypes } from "../../DataTypes/AlertTypes";
import { ImageView } from "../core/Helpers/ImageView";
import { LangItem } from "../core/Helpers/LangItem";
import { Fa } from "./Fa";
import { Loading } from "./Loading";
type lButton = {
    customStyle?: string;
    title?: string;
    type?: colorTypes;
    onClick: (any:any)=>void;
    icon?: string;
    iconColor?: string;
    iconSize?: string;
    iconOrder?:number;
    CustomImage?: any;
    orderImage?: number;
    tabindex?: number;
    new?: boolean;
    isSubmit?: boolean;
    tooltip?: {
        message: string;
        customStyle?: string;
    };
}

type stateLButton = {
    loading: boolean;
    classes: string;
}
class LButton extends React.Component {
    props: lButton;
    state: stateLButton
    constructor(props: lButton){
        super(props)
        this.props = props
        this.state = {
            loading: false,
            classes: ""
        }
        this.load = this.load.bind(this)
    }
    load(bool:boolean){
        this.setState({
            loading: bool
        })
    }
    get classList(){
        const ev = this
        return {
            classess: this.state.classes,
            add(some:string) {
                ev.setState({classes: some})
            },
            remove(some: string){
                ev.setState({classes: ""})
            }
        }
    }
    click = () => {
        this.props.onClick("")
    }
    render(){
        const {...p} = this.props
        const {loading, classes} = this.state
        if(loading){
            return <div className={`button button-type-${p?.type} ${p?.customStyle}`} >
                <button className="button-box" disabled>
                    <Loading color="white"/>
                </button>
            </div>
        }
        return <div className={`button button-type-${p?.type} ${p?.customStyle} ${classes}`} >
            <button className="button-box" onClick={p.onClick} tabIndex={p?.tabindex} type={p.isSubmit || p.tabindex===1 ? "submit":"button"}>
                {p?.tooltip && <div className={`tooltip ${p.tooltip.customStyle}`}>
                    <div className="tooltip-box">
                        <LangItem for={p.tooltip.message}/>
                    </div>
                </div>}
                {p?.new && <div className="new-tag"/>}
                {p?.icon && !p?.iconOrder && <div className="button-icon">
                    <Fa icon={p.icon} color={p?.iconColor} size={p?.iconSize}/>
                </div>}
                {p?.title && <div className="button-title">
                    <LangItem for={p.title}/>
                </div>}
                {p?.icon && p?.iconOrder && <div className="button-icon">
                    <Fa icon={p.icon} color={p?.iconColor} size={p?.iconSize}/>
                </div>}
                {p?.CustomImage && <div className={`button-icon order-${p?.orderImage}`}>
                    <ImageView source={p.CustomImage} alt="no photo"/>
                </div>}
            </button>
        </div>
    }
}

export {LButton}