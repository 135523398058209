import React from 'react'
import {BrowserRouter as Router} from 'react-router-dom'
import { navigator } from '../Controllers/Navigator'
import { Core } from '../Views/core/Core'
import { controllers } from '../Controllers/Controller'
import { HomePage } from '../Views/pages/HomePage'
import { TargetOriented } from '../Views/pages/TargetOriented'
import { PiecesOfHistory } from '../Views/pages/PiecesOfHistory'
import { JoinedPage } from '../Views/pages/JoinedPage'
// import { HomePage } from '../Views/pages/HomePage'
// import { SeriesPage } from '../Views/pages/SeriesPage'
// import { FavoritesPage } from '../Views/pages/FavoritesPage'

class MainNavigation extends React.Component {

    render(){
        const {home, targetOriented, piecesOfHistory, joined} = controllers().page
        const { currentPage } = navigator()
        // home, favorites, series,
        return <Router
            ref={(ref:any)=>{
                navigator().initNavigation(ref)
            }}
        >
            <Core>
                {currentPage === "/processing" ? <TargetOriented controller={targetOriented}/> 
                : currentPage === "/piecesofhistory" ? <PiecesOfHistory controller={piecesOfHistory}/> 
                : currentPage === "/joined" ? <JoinedPage controller={joined}/>
                : <HomePage controller={home}/>}
            </Core>
        </Router>
    }
}

export { MainNavigation }