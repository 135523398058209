import React from 'react'

type viewItemProps = {
    component?: any;
    children?: any;
    RightComponent?: any;
    controller?: any
}

// type viewItemState = {
//     version: number
// }

class ViewItem extends React.Component {
    props: viewItemProps
    // state: viewItemState
    constructor(props:viewItemProps){
        super(props)
        this.props = props
        // this.state = {
        //     version: 0
        // }
        // this.update = this.update.bind(this)
    }
    update = () => {
        // this.setState({
        //     version: this.state.version+1
        // })
        this.forceUpdate()
    }
}

export { ViewItem }