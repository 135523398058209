import { Model } from "../Core/Components/Model";


class LoaderController extends Model {
    private _visible: boolean
    private _miniLoading: boolean;
    constructor(){
        super()
        this._visible = true // DEFAULT VALUE
        this._miniLoading = false
    }

    get miniLoading(){
        return this._miniLoading
    }

    get visible(){
        return this._visible
    }

    show(){
        this.setVisible(true)
    }

    hide(){
        this.setVisible(false)
    }

    private setVisible(value: boolean){
        if(this._visible === value){
            return
        }
        this._visible = value
        this.updateMe()
    }
    
}

export { LoaderController }