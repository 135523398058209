import React from "react"
import { LangItem } from "../core/Helpers/LangItem";

type wowProps = {
    title: string;
    description?: string;
    children?: any
}

class WowText extends React.Component {

    props:wowProps
    constructor(props: wowProps){
        super(props)
        this.props = props
    }
    render(){
        const {title, description, children} = this.props
        return <div className='wow-text'>
        <div className='wow-text-container'>
            <div className='wow-text-title'>
                <LangItem for={title}/>
            </div>
            <div className='wow-text-description'>
                <LangItem for={children || description}/>
            </div>
        </div>
    </div>
    }
}

export {WowText}