import React from "react"
import { ImageView } from "../core/Helpers/ImageView"

type wowMainPhotoProps = {
    photo: string;
    selected: boolean;
}

class WowMainPhoto extends React.Component {
    props: wowMainPhotoProps
    constructor(props: wowMainPhotoProps){
        super(props)
        this.props = props
    }
    render() {
        const { photo, selected} = this.props
        return <div className={`main-wow-image ${selected ? 'main-wow-selected' : 'main-wow-opacity-0'}`}>
                <div className="main-wow-image-bg"/>
                <ImageView source={photo}/>
            </div>
    }
}

export { WowMainPhoto }