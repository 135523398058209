import React from 'react'
import { controllers } from '../../Controllers/Controller'
import { FooterView } from './FooterView'
import { MenuContactView } from './Menu/MenuContactView'
import { MenuHouperView } from './Menu/MenuHouper'
import { MenuView } from './MenuView'

class Core extends React.Component {
    props: {children: any}
    constructor(props: any){
        super(props)
        this.props = props
    }
    render(){
        const { menu, media, footer} = controllers()
        const { leftHouper, rightHouper, contactForm} = menu
        return <div className="core">
            <div className="core-box">
                <div className="menu-backblock"/>
                <MenuHouperView ref={leftHouper.set} controller={leftHouper} />
                <MenuHouperView 
                    ref={rightHouper.set} 
                    controller={rightHouper} 
                    RightComponent={<MenuContactView ref={contactForm.set} controller={contactForm}/>}    
                />
                <MenuView controller={menu} ref={menu.set}/>
                <div className="core-content" onClick={media.mainContentClicked}>
                    <div className="core-content-box">
                        {this.props.children}
                    </div>
                </div>
                <FooterView ref={footer.set} controller={footer}/>
            </div>
        </div>
    }
}

export { Core }