import React from "react";
import { WowGalleryItem } from "./WowGalleryItem";

type galeryProps = {
    photos: Array<string>
}

type galleryState = {
    selectedIndex: number
}

class WowGallery extends React.Component {
    props: galeryProps
    state: galleryState
    constructor(props: galeryProps){
        super(props)
        this.props = props
        this.state = {
            selectedIndex: 0
        }
    }

    setIndex = (n: number) => {
        this.setState({selectedIndex: n})
    }

    render() {
        const { photos } = this.props
        const { selectedIndex } = this.state
        return <div className="wow-gallery">
                <div className="wow-gallery-box">
                    <div className="wow-gallery-photos">
                        {photos && photos.map((p,i)=><WowGalleryItem key={i+p} icon={p} selected={selectedIndex===i} />)}
                    </div>
                    <div className="wow-gallery-points">
                        {photos && photos.map((p, i)=><div key={i+p} className="wow-gallery-point"/>)}
                    </div>
            </div>
        </div>
    }

}

export { WowGallery }