

export const UPDATE = {
    LANG: "lang",
    LOADER: "loader",
    AUTH: "auth",
    ALERT: "alert",
    MENU: "menu",
    TABLE: "table",
    MODAL: "modal",
    EXIST: "exist",
    USERINFO: "userinfo",
    LOADING: "loading",
    CAROUSEL: "carousel",
    DICTIONARY: "dictionary",
    LOGO: "logo",
}

export const TABLES = {
    SOME: "some",
}

export const MODALS = {
    SERVER_INFO: "server_info",
}

export const TYPE_ACTIONS = {
    LOADER: "LOADER",
    SET_USER_DATA: "SET_USER_DATA",
    SET_CHANNELS_DATA: "SET_CHANNELS_DATA",
    SET_TV_STATE: "SET_TV_STATE",
    SET_SELECTED_CHANNEL: "SET_SELECTED_CHANNEL",
    SET_FULL_SCREEN: "SET_FULL_SCREEN",
    SET_VOLUME: "SET_VOLUME",
    SET_PLAYING: "SET_PLAYING",
    AUTH_PASSED: "AUTH_PASSED",
}

export const WEB_ACTIONS = { 
    LOADER: "LOADER",
    FIRST_LOAD: "FIRST_LOAD",
    ALERT: "ALERT",
    NAVIGATION: "NAVIGATION",
    REQUEST: "REQUEST",
    PAYED: "PAYED",
    LANG: "LANG",
    CHANNELS_VISIBLE: "CHANNELS_MENU",
}

export const UPDATE_ACTIONS = {
    CORE: "core",
    VIDEO: "video",
    FULLSCREEN: "fullscreen",
    CONTROL: 'control',
    CHANNELS: 'channels',
    INFO: 'info'
}

export const DATA_ACTIONS = {
    TARRIFS: "TARRIFS",
    CHANNELS: "CHANNELS",
}