// import { MODALS } from "../Actions/types"


class ModalsController {

    // constructor() {
    // }
    
}

export { ModalsController }