import React from 'react' 
import { LangItem } from '../Helpers/LangItem'

type footerItemSuitProps = {
    children: any;
    title: string;
}

class FooterItemSuit extends React.Component {
    props: footerItemSuitProps
    constructor(props: footerItemSuitProps){
        super(props)
        this.props = props
    }

    render(){
        const {children, title} = this.props
        return <div className="footer-item-suit">
            <div className="footer-item-suit-box">
                <div className="footer-item-suit-title">
                    <LangItem for={title}/>
                </div>

                <div className="footer-item-suit-navigations">
                    {children}                    
                </div>
            </div>
        </div>
    }
}

export { FooterItemSuit }
