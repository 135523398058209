import React from "react";
import { langs } from "../../../DataTypes/languageTypes";


type languageProps = {
    selected:boolean;
    title: string;
    nameLang: langs;
    select: (lang: langs)=>void
}

class OneLanguageView extends React.Component {

    props: languageProps
    constructor(props: languageProps){
        super(props)
        this.props = props
    }

    onPress = () => {
        this.props.select(this.props.nameLang)
    }

    render() { 
        const { selected, title } = this.props
        return <div className={`one-lang-item ${selected ? 'lang-selected' : ''}`} onClick={this.onPress}>
            {title}
        </div>
    }

}

export { OneLanguageView }