import React from "react";
import { ImageView } from "../core/Helpers/ImageView";

type galleryItem = {
    selected: boolean;
    icon: string;
}

class WowGalleryItem extends React.Component {
    props: galleryItem;
    constructor(props: galleryItem){
        super(props)
        this.props = props
    }

    render() {
        const { selected, icon } = this.props
        return <div className={`wow-gallery-item ${selected ? 'wow-selected' : ''}`}>
            <div className="wow-gallery-item-box">
                <ImageView source={icon}/>
            </div>
        </div>
    }

}

export { WowGalleryItem }