import { ScrollController } from "../ScrollController"


type scrollSectionModel = {
    offset: number;
    sequence: number;
    scrollController: ScrollController
}

class ScrollSection {
    private _model: scrollSectionModel

    private _selected: boolean
    constructor(model: scrollSectionModel){
        this._selected = false
        this._model = model
    }

    get selected(){
        return this._selected
    }

    setSelected = (val: boolean = true, animate: boolean = false) => {
        this._selected = val
        if(val){
            this.scrollMe(animate)
        }
    }

    scrollMe = (animate: boolean = false) => {
        try{
            if(this.scrollController.scrollRef === null){
                return
            }
            this.scrollController.scrollRef.scrollTo({
                left: this.offset,
                top: 0, 
                behavior: animate ? 'smooth' : 'auto'
            })
        } catch(e){
            console.error(e)
        }
    }

    get scrollController(){
        return this._model.scrollController
    }

    get offset(){
        return this._model.offset
    }

    get sequence(){
        return this._model.sequence
    }

}

export { ScrollSection }