import React from "react";


type wowMainLineProps = {
    selected: boolean;
    click: (ind:number)=>void;
    myIndex: number;
}

class WowMainLine extends React.Component {
    props: wowMainLineProps 
    constructor(props:wowMainLineProps){
        super(props)
        this.props = props
    }

    clickMe = () => {
        const { click, myIndex } = this.props
        click(myIndex)
    }

    render() {
        const { selected } = this.props
        return <div className={`wow-main-line-container`} onClick={this.clickMe}>
            <div className={`wow-main-line ${selected ? "wow-main-selected": ""}`}>
                <div className="wow-main-line-box"></div>
            </div>
        </div>
    }
}

export { WowMainLine }