import { br } from "../../Constants/constants";
import { Timeout } from "../../Core/Components/Timeout";
import { ScrollController } from "../ScrollController"


class ScrollRolling {

    private _scrollController: ScrollController;
    private _endTimeout: Timeout;
    private _lastScroll: number;
    private _active: boolean;
    private _pixelDifference: number = br === "Safari" ? 50 : 30

    constructor(scrollController: ScrollController){
        this._scrollController = scrollController
        this._endTimeout = new Timeout(br === "Safari" ? 200 : br === "Firefox" ? 500 : 700);
        this._lastScroll = 0
        this._active = false
    }

    get scroll(){
        return this._scrollController
    }

    get sequences(){
        return this.scroll.sequences
    }

    onScrollEvent = (e: any) => {
        // return
        if(this._active){
            return
        }
        const {scrollLeft, scrollWidth, offsetWidth} = e.target
        if(this._lastScroll === 0){
            this._lastScroll =  scrollLeft
            return
        }
        if(Math.abs(this._lastScroll-scrollLeft)>this._pixelDifference){
            this.setEndTimeout()
            if(this._lastScroll>scrollLeft){
                this.sequences?.prevSection()
            } else {
                this.sequences?.nextSection()
            }
            const find = this.sequences?.getSelectedSection()
            if(find){

                // console.error("HEAR IS FINDOFFSET", find.offset);
                // console.error("SCROLL WIDTH OFFSETWIDTH", offsetWidth);
                // console.error("SCROLL WIDTH SCROLLWIDTH", scrollWidth);
                // console.error("SCROLL WIDTH SCROLLWIDTH - offset", scrollWidth - offsetWidth);
                // console.error("SCROLL WIDTH OFFSETWIDTH", find.offset> scrollWidth-offsetWidth);
                // if(br === "Safari" && find.offset+){

                // }
                if(br === "Safari"){
                    this.setLastScroll(scrollLeft)
                    return
                }
                if(br === "Firefox" && find.offset> scrollWidth-offsetWidth){
                    this.setLastScroll(scrollLeft)
                    return
                }
                this.setLastScroll(find.offset)
            }
        }
    }
    setLastScroll = (offset: number|null) => {
        if(offset == null){
            return
        }
        this._lastScroll = offset
    }

    makeScrollable = (bool: boolean = true) => {
        this._active = !bool
        try{
            this._scrollController.makeScrollable(bool)
            // if(this._scrollController.scrollRef){
            //     this._scrollController.scrollRef.style.overflow = bool ? 'auto' : 'hidden'
            // }
        } catch(e){
            console.error(e)
        }
    }

    // makeUnactive

    setEndTimeout = () => {
        // console.error("SCROLLABLE FALSE")
        // alert(br);
        this.makeScrollable(false)
        this._endTimeout.set(this.afterScrolled)
    }

    afterScrolled = () => {
        // console.error("SCROLL WAS ENDED")
        this.makeScrollable(this._scrollController.scrollableController)
    }




}

export { ScrollRolling }