import React from "react"
import { LButton } from "../Components/LButton"
import { LangItem } from "../core/Helpers/LangItem";
import { WowGallery } from "./WowGallery";


type wowCarouselProps = {
    photos?: Array<string>
    title: string;
    children: any;
    click: ()=>void;
}

class WowDestiny extends React.Component {
    props: wowCarouselProps
    constructor(props:wowCarouselProps){
        super(props)
        this.props = props
    }
    render(){
        const { photos, title, children, click } = this.props
        return <div className='wow-connect'>
        <div className='wow-connect-container'>
            <div className='wow-connect-carousel'>
                {photos && <WowGallery photos={photos}/>}
            </div>
            <div className='wow-connect-info'>
                <div className='wow-connect-info-container'>
                    <div className='wow-connect-title'><LangItem for={title}/></div>
                    <div className='wow-connect-description'>
                        <LangItem for={children}/>
                    </div>
                    <div className='wow-connect-button'>
                        <LButton 
                            title='Connect'
                            icon="arrow-right"
                            iconOrder={1}
                            type='suit'
                            customStyle='connect-button'
                            onClick={click}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
    }

}

export { WowDestiny }