import { AlertController } from "./AlertController";
import { LoaderController } from "./LoaderController";
import { MenuController } from "./MenuController";
import { ModalsController } from "./ModalsController";
import { MediaController } from './MediaController'
import { PageController } from "./PageController";
import { UserController } from "./UserController";
// import { Core } from "../Views/core/Player/Models/Core";
import { LanguageController } from "./LanguageController";
import { FooterController } from "./PageControllers/FooterController";
import { MainScroll } from "./MainScroll";

class ControllersImpl {
  
  private readonly _loaderController: LoaderController;
  private readonly _languageController: LanguageController;
  private readonly _alertController: AlertController;
  private readonly _menuController: MenuController;
  private readonly _footerController: FooterController;
  private readonly _modalsController: ModalsController;
  private readonly _mediaController: MediaController
  private readonly _pageController: PageController;
  private readonly _userController: UserController;
  private readonly _scroll: MainScroll;

  constructor() {
    this._loaderController = new LoaderController();
    this._languageController = new LanguageController();
    this._alertController = new AlertController();
    this._menuController = new MenuController();
    this._footerController = new FooterController();
    this._modalsController = new ModalsController();
    this._mediaController = new MediaController();
    this._pageController = new PageController();
    this._userController = new UserController();
    this._scroll = new MainScroll();
  }
  /**
   * Getter for navigation controller class
   * @return NavigationController
   */

  get loader(){
      return this._loaderController
  }

  get language(){
    return this._languageController
  }

  get alert(){
    return this._alertController
  }

  get menu(){
    return this._menuController
  }

  get footer(){
    return this._footerController
  }

  get modals(){
    return this._modalsController
  }

  get media(){
    return this._mediaController
  }

  get page(){
    return this._pageController
  }

  get user(){
    return this._userController
  }

  get scroll(){
    return this._scroll
  }
}

// @ts-ignore
global.__app__ = global.__app__ || {};
// @ts-ignore
global.__app__.controllers =
  // @ts-ignore
  global.__app__.controllers || new ControllersImpl();

export function controllers(): ControllersImpl {
  // @ts-ignore
  return global.__app__.controllers;
}
