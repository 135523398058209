
import React from 'react'
import { LangItem } from '../Helpers/LangItem';

type hooperProps = {
    childrens?: any;
    title: string;
    click: ()=>void
}

class HooperItemView extends React.Component {

    props: hooperProps
    constructor(props: hooperProps){
        super(props)
        this.props = props
    }

    render() {
        const {title, click} = this.props
        return <div className='hooper-item-box' onClick={click}>
            <div className='hooper-item'>
                <LangItem for={title}/>
            </div>
        </div>
    }
}

export { HooperItemView }