import React from 'react'
// import { coreupdate } from '../Actions/functions';
// import { UPDATE } from '../Actions/types';
import { controllers } from '../Controllers/Controller'
// import { loadData, UserDataProvider } from './UserDataProvider';

class AppStateController extends React.Component {
    
    restored: boolean = false

    componentDidMount(){
        window.addEventListener('resize', controllers().media.reinit);
        AppStateController.restoreData()
        controllers().scroll.makeCustomListeners()
        controllers().loader.hide()
    }

    componentWillUnmount(){
        window.removeEventListener('resize', controllers().media.reinit);
    }

    static restoreData(){
        controllers().language.restoreLang()
    }

    render(){
        return null
    }
}

export { AppStateController }