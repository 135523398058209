import React from 'react'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import {fas} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(
  fab,
  fas
)


class Fa extends React.Component {
    props: any

    render(){
        const {icon, ...payloads} = this.props
        const type = payloads.type !==undefined ? payloads.type : 'fas'
        return(
            <FontAwesomeIcon
                icon={[type, icon]}
                {...payloads}
            />
        )
    }
}

export { Fa }