import React from "react";
// import { controllers } from "../../Controllers/Controller";
import { Fa } from "../Components/Fa";
import { WowCarouselItem } from "./WowCarouselItem";

type wowCarouselProps = {
    photos: Array<string>
}
class WowCarousel extends React.Component {
    props: wowCarouselProps;
    scrollRef:any;
    anyItemRef: any;
    currentPhoto:number = 0
    constructor(props:wowCarouselProps){
        super(props)
        this.props = props
    }
    setScrollRef = (ref:any) => {
        this.scrollRef = ref
    }
    setAnyItemRef = (ref: any) => {
        this.anyItemRef = ref
    }
    nextScroll = () => {
        this.currentPhoto = this.currentPhoto+1;
        if(this.currentPhoto>this.props.photos.length-1){
            this.currentPhoto = 0
        }
        const refWidth = this.anyItemRef.width
        this.scroll(0, refWidth*this.currentPhoto)
    }
    prevScroll = () => {
        this.currentPhoto = this.currentPhoto-1;
        if(this.currentPhoto<0){
            this.currentPhoto = this.props.photos.length-1
        }
        const refWidth = this.anyItemRef.width
        this.scroll(0, refWidth*this.currentPhoto)
    }
    scroll = (y: number, x: number = 0) => {
        // console.error(this.scrollRef)
        try{
            if(this.scrollRef === null){
                // alert("NO SCROLL REF")
                return console.log(`can't scroll now`);
            }
            if(typeof this.scrollRef.scroll === "function"){
                // alert("SCROLL IS FUNCTION")
                this.scrollRef.scroll(x, y)
            } else if(typeof this.scrollRef.scrollTo === "function"){
                // alert("SCROLLTO")
                this.scrollRef.scrollTo(x, y)
            } else {
                alert("SCROLLTOP")
                // controllers().alert.error(JSON.stringify(this._scrollRef));
                // document.body.scrollTop = document.documentElement.scrollTop = 0;
            }
        } catch(e){
            console.log('scroll ex', e)
        }
    }
    render() {
        const { photos } = this.props
        return <div className='wow-carousel'>
                <div className='wow-carousel-container'>
                    <div className='wow-carousel-buttons'>
                        <div className='wow-carousel-buttons-item' onClick={this.prevScroll}>
                            <Fa icon="angle-left"/>
                        </div>
                        <div className='wow-carousel-buttons-item' onClick={this.nextScroll}>
                            <Fa icon="angle-right"/>
                        </div>
                    </div>
                    <div className='wow-carousel-gallery' ref={this.setScrollRef}>
                        <div className='wow-carousel-gallery-container'>
                            {photos && photos.map((onephoto, i)=><WowCarouselItem key={i} ref={this.setAnyItemRef} photo={onephoto}/>)}
                        </div>
                    </div>
                </div>
            </div>
    }
}

export { WowCarousel }