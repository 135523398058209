export const saveData = async (name:string, value:any) => {
  if (typeof value !== 'string') {
    const receivedValue = JSON.stringify(value);
    try {
      await localStorage.setItem(name, receivedValue);
    } catch (e) {
      throw e;
    }
  } else {
    try {
      await localStorage.setItem(name, value);
    } catch (e) {
      throw e;
    }
  }
};