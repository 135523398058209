

export type alertTypes = "hidden" | "success" | "primary" | "error" | "warning"
export type colorTypes = "success" | "primary" | "error" | "warning" | "suit" | "none" | "secondary"

export const ALERT_TYPES = {
    HIDDEN: "hidden",
    SUCCESS: "success",
    PRIMARY: "primary",
    ERROR: "error",
    WARNING: "warning"
}

export const COLOR_TYPES = {
    SUCCESS: "success",
    PRIMARY: "primary",
    ERROR: "error",
    WARNING: "warning",
    SUIT: "suit",
    NONE: "none",
    SECONDARY: "secondary"
}