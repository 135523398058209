import { Model } from "../../Core/Components/Model";

type hooperItemType = {
    title:string; 
    click:()=>void
}

type hooperItemsType = Array<hooperItemType>

class MenuHouper extends Model {

    private _visible: boolean
    private _position: "left"|"right"
    private _hooperItems: hooperItemsType
    constructor(position: "left"|"right", hooperItems:hooperItemsType=[]){
        super()
        this._visible = false
        this._position = position
        this._hooperItems = hooperItems
    }

    get hooperItems(){
        return this._hooperItems
    }

    get position(){
        return this._position
    }

    get visible(){
        return this._visible
    }

    setVisible = (bool: boolean) => {
        if(this._visible === bool){
            return
        }
        this._visible = bool
        this.updateMe()
    }
}

export { MenuHouper }