import React from "react";
import { LangItem } from "../core/Helpers/LangItem";
import { WowMainLine } from "./WowMainLine";
import { WowMainPhoto } from "./WowMainPhoto";


type mainCarouselProps = {
    photos: Array<string>;
    title?: string;
    description?: string;
    centeredTitle?: boolean;
    customStyle?: string;
}

type mainCarouselState = {
    selected: number; 
}
class MainCarousel extends React.Component {
    props: mainCarouselProps
    state: mainCarouselState
    startedInterval: boolean = false;
    needRollWithTimeout:boolean = true //need roll with timeout
    intervalTimeout: number = 6000//miliseconds
    myInterval: any;
    constructor(props:mainCarouselProps){
        super(props)
        this.props = props
        this.state = {
            selected: -1
        }
    }

    componentDidMount() {
        if(!this.startedInterval){
            this.startedInterval = true
            setTimeout(()=>this.changeIndex(0),50);
            // this.startInterval()
            // this.changeIndex(0);
        }
    }
    togglePhotos = () => {
        const count = this.props.photos.length
        const nextPhoto = this.state.selected+1;
        this.setState({selected: nextPhoto+1>count ? 0 : nextPhoto})
    }

    clearInterval = () => {
        clearInterval(this.myInterval)
    }
    startInterval = () => {
        if(this.needRollWithTimeout){
            this.myInterval = setInterval(this.togglePhotos, this.intervalTimeout)
        }
    }

    changeIndex = (int: number) => {
        this.clearInterval()
        this.setState({selected: int})
        this.startInterval()
    }


    render() {
        const { photos, title, description, centeredTitle, customStyle } = this.props
        const { selected } = this.state
        return <div className={`main-wow ${centeredTitle ? "centered-title" : ""} ${customStyle ? customStyle : ''}`}>
                <div className='main-wow-container'>
                    <div className="main-wow-carousel">
                        {photos && photos.map((p,i)=><WowMainPhoto key={i} photo={p} selected={i===selected}/>)}
                    </div>
                    <div className="main-wow-lines">
                        <div className="main-wow-lines-box">
                            {photos && photos.map((p,i)=><WowMainLine key={i} selected={i===selected} myIndex={i} click={this.changeIndex}/>)}
                        </div>
                    </div>
                    <div className='main-wow-text'>
                        <div className='main-wow-sm-title'>
                            <LangItem for={title||""}/>
                        </div>
                        <div className='main-wow-beauty-text'>
                            <LangItem for={description||""}/>
                        </div>
                    </div>
                </div>
            </div>
    }

}

export { MainCarousel }