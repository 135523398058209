import { MenuContact } from "../../../Controllers/Menu/MenuContact";
import { ViewItem } from "../../Components/Classes/ViewItem";
import { LButton } from "../../Components/LButton";
import { LInput } from "../../Components/LInput";
import { LSelect } from "../../Components/LSelect";


class MenuContactView extends ViewItem {

    get controller(): MenuContact{
        return this.props.controller
    }

    render(){
        const { onSubmitForm, setTypeValue, type} = this.controller
        return <div className="submit-form">
            <form className="submit-form-target" onSubmit={onSubmitForm}>
                <LSelect
                    title="Request type"
                    onChange={setTypeValue}
                    customStyle={"typeselector"}
                    value={type}
                    name="type"
                    selectOptions={[
                        {
                            label: "Complate",
                            value: "Complate"
                        },
                        {
                            label: "Mechanics",
                            value: "Mechanics"
                        },
                        {
                            label: "Body",
                            value: "Body"
                        },
                        {
                            label: "Interior",
                            value: "Interior"
                        },
                        {
                            label: "Electrics",
                            value: "Electrics"
                        },
                        {
                            label: "Detailing",
                            value: "Detailing"
                        },
                    ]}
                />
                <LInput 
                    type="phone" 
                    title="Enter cell phone number" 
                    name="phonenumber" 
                    required
                    placeholder="+420776227750" 
                    customStyle="input-pref"
                />
                <LInput 
                    type="text" 
                    title="Enter your contact name" 
                    name="fullname" 
                    required
                    placeholder="Alexandr Chobal" 
                    customStyle="input-pref"
                />
                <LInput 
                    type="email" 
                    title="Enter your email address" 
                    name="email" 
                    required
                    placeholder="chobalrestoration@gmail.com" 
                    customStyle="input-pref"
                />
                <LButton title="Submit" isSubmit customStyle="input-submit-pref" onClick={()=>{}}/>
            </form>
        </div>
    }
}

export { MenuContactView }