import { Lang } from "./Lang";


class English extends Lang {

    constructor(){
        super("en")
        this.vals = [
            // default entries
            {"": ""},
            {"A RELENTLESS PURSUIT OF EXCELLENCE": "A RELENTLESS PURSUIT OF EXCELLENCE"},
            {"LUXURY THROUGH CHOICE":"LUXURY THROUGH CHOICE"},
            {"Our unique perspective enables special partnerships. In collaboration with each owner, we focus on what is important to them.": "Our unique perspective enables special partnerships. In collaboration with each owner, we focus on what is important to them."},
            {"About us": "About us"},
            {"Cell phone number": "Cell phone number"},
            {"Chobal is a team of people dedicated to improving the achievements of the previous past into a brighter future. Constant desire to improve and restore the best models of cars": "Chobal is a team of people dedicated to improving the achievements of the previous past into a brighter future. Constant desire to improve and restore the best models of cars"},
            {"Classic & Sportscar Restorations": "Classic & Sportscar Restorations"},
            {"Connect": "Connect"},
            {"Details makes perfect": "Details makes perfect"},
            {"EVERYTHING IS IMPORTANT": "EVERYTHING IS IMPORTANT"},
            {"Enter cell phone number": "Enter cell phone number"},
            {"Enter your contact name": "Enter your contact name"},
            {"Enter your email address": "Enter your email address"},
            {"Enter your phone number": "Enter your phone number"},
            {"Home": "Home"},
            {"How we work": "How we work"},
            {"JOIN": "JOIN"},
            {"Navigation": "Navigation"},
            {"Our restorations are exhibited at various European competitions, such as Classic Expo and others. We direct our ambitions to realize the dreams of many connoisseurs of retro cars. Our works are expertly evaluated by machine critics of German quality at the highest level and have successful evaluations": "Our restorations are exhibited at various European competitions, such as Classic Expo and others. We direct our ambitions to realize the dreams of many connoisseurs of retro cars. Our works are expertly evaluated by machine critics of German quality at the highest level and have successful evaluations"},
            {"Our team is focused on the restoration of youngtimer and oldtimer cars, which were legends of their time. Only high-quality details and materials we use for restoration. You can be sure that your car will make perfect condition and past thousans kilometers without any problems": "Our team is focused on the restoration of youngtimer and oldtimer cars, which were legends of their time. Only high-quality details and materials we use for restoration. You can be sure that your car will make perfect condition and past thousans kilometers without any problems"},
            {"Our unique perspective enables special partnerships. In collaboration with each owner, we focus on what is important to them.": "Our unique perspective enables special partnerships. In collaboration with each owner, we focus on what is important to them."},
            {"Pieces of history": "Pieces of history"},
            {"Privacy": "Privacy"},
            {"Process of work":"Process of work"},
            {"SINCE 2006": "SINCE 2006"},
            {"Submit": "Submit"},
            {"Success in our mission requires a unique approach Every detail, no matter how small, must be considered and optimized. These three words are the constant mantra that guides our pursuit of perfection.": "Success in our mission requires a unique approach Every detail, no matter how small, must be considered and optimized. These three words are the constant mantra that guides our pursuit of perfection."},
            {"Target oriented": "Target oriented"},
            {"We are a full-service restoration facility specializing in classic cars,with an emphasis on sports and racing cars. Our talented, dedicated staff is responsible for the restoration, maintenance, and support of many of the world's most beautiful motorcars, driven extensively by the most demanding collectors.": "We are a full-service restoration facility specializing in classic cars,with an emphasis on sports and racing cars. Our talented, dedicated staff is responsible for the restoration, maintenance, and support of many of the world's most beautiful motorcars, driven extensively by the most demanding collectors."},
            {"Where we are": "Where we are"},
            {"Wow factor; the response you can expect - for award winning quality auto restoration service; transforming details to show quality - one bolt at a time. Every detail is important in our work, we do a full restoration of every part. From the nut to the engine, we make sure that every part is in its place. Every detail of the interior will be restored with love": "Wow factor; the response you can expect - for award winning quality auto restoration service; transforming details to show quality - one bolt at a time. Every detail is important in our work, we do a full restoration of every part. From the nut to the engine, we make sure that every part is in its place. Every detail of the interior will be restored with love"},
            {"support": "support"},
            {"Thank you for joining, we will anser soon.": "Thank you for joining, we will anser soon."},

            {"Classic car restoration details": "Classic car restoration details"},
            {"Car restoration from 60th to 90th": "Car restoration from 60th to 90th"},
            {"We direct our ambitions to realize the dreams of many connoisseurs of retro cars. Our works are expertly evaluated by machine critics of German quality at the highest level and have successful evaluations": "We direct our ambitions to realize the dreams of many connoisseurs of retro cars. Our works are expertly evaluated by machine critics of German quality at the highest level and have successful evaluations"},

            {"Request type": "Request type"},
            {"Complate": "Complate"},
            {"Mechanics": "Mechanics"},
            {"Body": "Body"},
            {"Interior": "Interior"},
            {"Electrics": "Electrics"},
            {"Detailing": "Detailing"},
        ]
    }
}

export { English }