import { Model } from "../../Core/Components/Model";
// import { loadData, UserDataProvider } from "../../Core/UserDataProvider";

type onesocialbutton = {
    name: string;
    value: string;
}

class FooterController extends Model {

    private _socials: Array<onesocialbutton>

    constructor(){
        super()
        this._socials = []
        this.getSocials()
    }

    get socials(){
        return this._socials
    }

    getSocials = async () => {
        // const response = await loadData(UserDataProvider.getSocials, {})
        // if(response.statusCode!==200){
        //     return
        // }
        // try{
        //     this._socials = JSON.parse(response.data)
        //     this.updateMe()
        // } catch(e){
        //     console.log("error")
        // }
    }
}

export {FooterController}