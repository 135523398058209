import React from 'react'

export type pageProps = {
    children?: any;
    controller: any;
    match?: any;
}

class PageView extends React.Component {
    props: pageProps;
    constructor(props:pageProps){
        super(props)
        this.props = props
    }

    componentDidMount(){
        this.props.controller.pageMounted();
    }

    render(){
        return <></>
    }
}

export { PageView }