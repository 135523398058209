import { UPDATE } from "../../../Actions/types";
import { controllers } from "../../../Controllers/Controller";
import { UpdateComponent } from "../../../Core/Components/UpdateComponent";
import { RW } from "../../../Helpers/LangHelper";

type langProps = {
    for: string;    
    c?:boolean
}

class LangItem extends UpdateComponent {
    props: langProps
    constructor(props: langProps){
        super(props)
        this.type = UPDATE.LANG
        this.props = props
    }
    render() {
        // console.log(this.props.for, RW(this.props.for))
        // controllers().language.addItem(this.props.for)
        return <>{typeof RW(this.props.for) === "string" ? RW(this.props.for):this.props.for}</>
    }
}

export { LangItem }