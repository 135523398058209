import React from "react";
// import { Fa } from "../Components/Fa";
import { ImageView } from "../core/Helpers/ImageView";

type wowCarouselItemProps = {
    photo: any
}
class WowCarouselItem extends React.Component {
    props: wowCarouselItemProps
    myRef: any
    constructor(props:wowCarouselItemProps){
        super(props)
        this.props = props
    }
    setMyRef = (ref: any) => {
        this.myRef = ref
    }
    get width(){
        return this.myRef.offsetWidth
    }
    render() {
        const { photo } = this.props
        return <div className='wow-carousel-gallery-item' ref={this.setMyRef}>
                <div className='wow-carouse-gallery-item-container'>
                    <ImageView source={photo}/>
                </div>
            </div>
    }
}

export { WowCarouselItem }