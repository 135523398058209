import React from 'react'
import { MenuHouper } from "../../../Controllers/Menu/MenuHouper";
import { ViewItem } from "../../Components/Classes/ViewItem";
import { LButton } from "../../Components/LButton";
import { HooperItemView } from "./HooperItem";
import Quit from '../../../assets/images/buttons/Quit.png'
import { ContactItemView } from '../../Components/ContactItem';
import { LanguagesMenu } from './LanguagesMenu';

class MenuHouperView extends ViewItem {

    get controller(): MenuHouper{
        return this.props.controller
    }

    render() {
        const {RightComponent} = this.props
        const { position, visible, hooperItems, setVisible} = this.controller
        return <div className={`menu-box-drawer ${position}-menu-box ${visible ? "menu-drawer-opened" : ""}`}>
            <div className='menu-drawer-main'>
                <div className='menu-drawer-main-box'>
                    {RightComponent && RightComponent}
                    {hooperItems && hooperItems.map(h=><HooperItemView key={h.title} title={h.title} click={h.click}/>)}
                    <LanguagesMenu/>
                </div>
            </div>
            <div className="menu-drawer-next">
                <div className="menu-drawer-close-box">
                    <LButton CustomImage={Quit} customStyle="quit-menu-button" onClick={()=>{
                        setVisible(false)
                    }}/>
                </div>
                <div className="menu-drawer-contact-info">
                    <div className='contact-info'>
                        <ContactItemView title="Cell phone number" icon="phone" href="tel:+420776227750" value="+420 77 622 7750"/>
                        {/* <ContactItemView title="WhatsApp" icon="phone" href="https://wa.me/+420776227750" value="+420 77 622 7750"/> */}
                        <ContactItemView 
                            title="Instagram" 
                            icon="hashtag" 
                            href="https://instagram.com/chobal_restoration?igshid=YmMyMTA2M2Y=" 
                            value="@chobal_restoration"
                        />
                    </div>
                </div>
            </div>
        </div>
    }

}

export { MenuHouperView }