import { UPDATE } from "../../../Actions/types";
import { controllers } from "../../../Controllers/Controller";
import { UpdateComponent } from "../../../Models/Components/UpdateComponent";
import { OneLanguageView } from "./OneLanguageView";



class LanguagesMenu extends UpdateComponent {

    constructor(props:any){
        super(props)
        this.type = UPDATE.LANG
    }

    render() { 
        const { lang, langs, setLanguage} = controllers().language
        return <div className="language-menu">
            <div className="language-menu-box">
                {langs && langs.map(langItem => <OneLanguageView key={langItem.name} title={langItem.name} selected={langItem.name === lang?.name} nameLang={langItem.name} select={setLanguage}/>)}
            </div>
        </div>
    }
}

export { LanguagesMenu }