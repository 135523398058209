import { controllers } from "./Controller";
import { ScrollController } from "./ScrollController";

const point = 250;
class MainScroll extends ScrollController {

    constructor(){
        super()
        this.customListenersToScroll(this.onScrollEventNow)
    }
    onScrollEventNow = (e: any) => {
        // console.error("ON SCROLL EVENT HEAR");
        if(e == null){
            return false
        }
        // console.error("ON SCROLL", e)
        const {scrollTop} = e.target
        const { isBlured } = controllers().menu
        if(scrollTop>point && !isBlured){
            controllers().menu.setIsBlured(true)
        } else if(scrollTop<point && isBlured){
            controllers().menu.setIsBlured(false)
        }
        return true
    };
}

export { MainScroll }