
export class AppSettings { 
    private static _baseUrl = "https://chobal.com"
    private static _endpoint = "https://control.chobal.com"
    private static _loadendpoint = "https://control.chobal.com"
    private static _testendpoint = "https://control.chobal.com"
    private static _sitename = "Chobal restoration"
    private static _sitedescription = ""
    private static _devMode = true
    
    public static get loadendpoint(){
        return this._loadendpoint
    }
    public static get endpoint(){
        return this._devMode ? this._testendpoint : this._endpoint
    }

    public static get sitename() {
        return this._sitename
    }
    
    public static get sitedescription() {
        return this._sitedescription
    }

    public static get devMode(){
        return this._devMode;
    }

    public static get baseUrl(){
        return this._baseUrl
    }
}