import { Model } from "../../Core/Components/Model"
import { HandleTask } from "../../Helpers/minhelpers/HandleTask";

import { ScrollController } from "../ScrollController"
import { ScrollSection } from "./ScrollSection"

type sequencesModel = {
    scrollController: ScrollController;
}

class Sequences extends Model {
    private _model: sequencesModel
    private _sections: Array<ScrollSection>
    private _handler: HandleTask;
    private _lastScrollWidth: number;
    private _scrollable: boolean
    private _prev: boolean;
    private _next: boolean;

    constructor(model: sequencesModel){
        super()
        this._model = model;
        this._sections = [];
        this._handler = new HandleTask();
        this._lastScrollWidth = 0;
        this._scrollable = true
        this._prev = true
        this._next = true
    }

    get prev(){
        return this._prev
    }

    get next(){
        return this._next
    }

    get scrollable(){
        return this._scrollable
    }

    setScrollable(bool: boolean){
        // if(this._scrollable == bool){
        //     return
        // }
        // // this._scrollable = bool
        this.updateMe()
    }

    get scrollController(){
        return this._model.scrollController
    }

    get sections(){
        return this._sections
    }

    clearSections = () => {
        this._sections = []
    }

    makeSections = (withSelection: boolean = false) => {
        // console.error("SECTIONS SSS", this.scrollController, this.scrollController.scrollRef, withSelection)
        if(this.scrollController.scrollRef == null){
            // console.error('scroll ref null');
            return
        }
        try{
            const {scrollWidth, offsetWidth, scrollLeft} = this.scrollController.scrollRef
            // console.error('sw ow', this._lastScrollWidth, scrollWidth, offsetWidth);
            // console.error('ow ',offsetWidth);
            if(this._lastScrollWidth === scrollWidth){
                return
            }
            this._lastScrollWidth = scrollWidth
            this.clearSections()
            const itemsLength = Math.ceil(scrollWidth/offsetWidth)
            for (let i = 0; i < itemsLength; i++) {
                this._sections.push(new ScrollSection({
                    offset:i*offsetWidth, 
                    sequence: i, 
                    scrollController: this.scrollController
                }))
            }
            // console.error('sections', this._sections)
            // console.warn('SECTIONS', this._sections)
            // console.error('sections', this._sections);
            // console.warn('WITH SELECTION', withSelection)
            if(withSelection){
                this.scrollToSectionByX(scrollLeft+offsetWidth-100, true);
            }
            this._handler.do()
        } catch(e){
            console.error(e)
        }
    }

    scrollToSectionByX = (x: number, animate: boolean = false) => {
        if(!this._sections.length){
            return
        }
        const filter = this._sections.filter(s=>s.offset<=x)
        if(filter){
            const find = filter[filter.length-1]
            if(find){
                this.makeSelected(find, animate)
            }
        }
    }

    makeSelected = (sequence: ScrollSection, animate: boolean = false) => {
        this.removeScrollable()
        this.removeSelected()
        sequence.setSelected(true, animate)
        this.firefoxkitFix()
        this.checkLines()
    }

    removeScrollable = () => {
        this.scrollController.scrollRolling?.setEndTimeout()
    }

    firefoxkitFix = () => {
        // firefoxkid fix
        this.scrollController.scrollRolling?.setLastScroll(this.getSelectedSection()?.offset||null)
    }

    checkLines = () => {
        const prev = this.findSection("prev") !== undefined
        const next = this.findSection("next") !== undefined
        
        if(prev !== this._prev || next !== this._next){
            this._prev = prev
            this._next = next
            this.updateMe()
        }
    }

    removeSelected = () => {
        const find = this._sections.find(s=>s.selected)
        if(find){
            find.setSelected(false)
        }
    }

    nextSection = () => {
        const current = this._sections.find(s=>s.selected)
        // console.log("NEXT SECTION", current)
        // console.log("NEXT SECTION2", this._sections)
        if(current){
            const find = this._sections.find(s=>s.sequence===(current.sequence+1))
            // console.log("find SECTION", find)
            if(find){
                this.makeSelected(find, true)
            }
        }
    }

    prevSection = () => {
        const current = this._sections.find(s=>s.selected)
        if(current){
            const find = this._sections.find(s=>s.sequence===(current.sequence-1))
            if(find){
                this.makeSelected(find, true)
            }
        }
    }

    findSection = (need: "next"|"prev") => {
        const current = this._sections.find(s=>s.selected)
        if(!current){
            return
        }
        const needSection = need==="next" ? current.sequence+1 : current.sequence-1
        return this._sections.find(s=>s.sequence === needSection)
    }

    getSelectedSection(){
        return this._sections.find(i=>i.selected)
    }

}

export { Sequences }